import React, { useEffect } from "react"
import { Outlet } from "react-router-dom"
import { useDispatch } from "react-redux";
import { ToastContainer } from 'react-toastify';
import DashboardNavbar from "./Navbar"
import { useAppSelector } from "../app/hooks";
import { getUserConfig, resetState } from '../features/dashboard/userSlice';
import SideMenu from "./SideMenu";

export default function Layout() {

  const dispatch = useDispatch<any>();
  const { config, isLoading, isSuccess, isError, msg } = useAppSelector((state: any) => state.user)

  useEffect(()=> {
    if (!config) {
      console.log('getting config')
      dispatch(getUserConfig())
    }

  },[dispatch])
  
  useEffect(()=> {
    if (isSuccess) {
      dispatch(resetState())
    }

  },[dispatch])
  
  return (
    <>
    <DashboardNavbar />
    <SideMenu />
    {config 
      ? <Outlet /> 
      : ''
    }
    <ToastContainer 
      pauseOnHover={false}
      pauseOnFocusLoss={false}
      autoClose={2000}
      hideProgressBar={true}
    />
    </>
  )
}

export const getLocalStorage = (key: any):any => {
    if(!key) return '';
    else {
        try {
            let result = localStorage.getItem(key) || '';
            if (!result || result === "undefined" || result === 'null') result = '';
            else result = JSON.parse(result);
            return result;
        }
        catch(err) {
            console.log("Error Accessing Local Storage.")
            return ''
        }
    }
}
export const setLocalStorage = (key: any, value: any) => {
    try{
        localStorage.setItem(key, JSON.stringify(value))
    } catch(err) {
        console.log("Error Accessing Local Storage.")
    }
}

export const removeLocalStorage = (key: any) => {
    try {
        if (getLocalStorage(key)) localStorage.removeItem(key)
    } catch(err) {
        console.log("Error Accessing Local Storage.")
    }
}

import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { isCookieEnabled } from '../helper/auth'

function Cookie() {
  const navigate = useNavigate();
  useEffect(()=> {
    if (isCookieEnabled()) navigate('/', {replace: true})
  },[navigate])

  return (
    <div>Cookies must be enabled to use Magic Trade Journal.</div>
  )
}

export default Cookie
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import Chart from 'react-apexcharts'
import dayjs from "dayjs";
import { useAppSelector } from "../../../../app/hooks";
import './_charts.scss';

const getTradeChartData = (tradeData:any)=> {
  let data:any = [];
  tradeData.forEach((date:any)=> {
    data.push([convertDate(date[0]), Number(date[1]).toFixed(2)])

  })
  return data;
}

const convertDate = (date: number | string) => {
  return dayjs(date.toString(), 'YYYYMMDDhhmmss').toDate();
}

const OverviewChart = () => {
  const { stats } = useAppSelector((state: any) => state.trade)
  const {firstTradeDate, accumDailyPnl } = stats;

  let chartOptions = {
    series: [{
      name: "Total P&L",
      data: [

      ]
    }],
    options: {
      colors: ['#e1b382'],
      chart: {
        id: 'area-datetime',
        type: 'area',

        height: 250,
        zoom: {
          enabled: true,
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: '#000',
              opacity: 0.4
            },
            stroke: {
              color: '#e1b382',
              opacity: 0.4,
              width: 1
            }
          }
        },
      },
      noData: {
        text: 'No Data',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: -25,
        style: {
          color: 'lightgray',
          fontSize: '32px',
        }
      },
      stroke: {
        curve: 'smooth',
        colors: ['black'],
        width: 2
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: 'hollow',
        colors: ['#e1b382']
      },
      xaxis: {
        type: 'datetime',
        labels: {
          offsetX: 20,
          style: {
            colors: [],
            fontSize: '12px',
            fontFamily: 'JetBrains Mono, monospace',
            fontWeight:'600'
          },
        },
      },
      yaxis: {
        forceNiceScale: true,
        labels: {
          formatter: function (value) {
            return '$' + value.toLocaleString("en-US");
          },
          style: {
            colors: [],
            fontSize: '12px',
            fontFamily: 'JetBrains Mono, monospace',
            fontWeight:'600'
          },
        },
      },
      tooltip: {
        x: {
          format: 'MM/dd/yyyy'
        },
        followCursor: true,
        style: {
          fontSize: '12px',
          fontFamily: 'monospace'
        },
      },
      grid: {
        borderColor: '#dadada',
        padding: {
          left: 10,
          right:20
        }
      },
      fill: {
        colors: ['#2d545e'],
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.9,
          opacityTo: 1,
        },
      },
    } as ApexOptions,
    selection: 'ytd',

  };

  const [state, setState] = useState(chartOptions);

  useEffect(()=> {

    setState({...state, series: [{...state.series[0], data: getTradeChartData(accumDailyPnl)}]  });

  },[accumDailyPnl])


  const updateData = (timeline: any) => {
    setState({ ...state, selection: timeline })

    switch (timeline) {
      case 'one_month':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date().setMonth(new Date().getMonth()-1),
          new Date().getTime()
        )
        break
      case 'six_months':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date().setMonth(new Date().getMonth()-6),
          new Date().getTime()
        )
        break
      case 'one_year':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date().setFullYear(new Date().getFullYear()-1),
          new Date().getTime()
        )
        break
      case 'ytd':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date(new Date().getFullYear(), 0, 1).getTime(),
          new Date().getTime()
        )
        break
      case 'all':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date(convertDate(firstTradeDate)).getTime(),
          new Date(convertDate(accumDailyPnl[accumDailyPnl.length-1][0])).getTime()
        )
        break
      default:
    }
  }


  return (

    <div id="chart">
      <div className="toolbar">

        <button id="one_month"

          onClick={() => updateData('one_month')} className={(state.selection === 'one_month' ? 'active' : '')}>
          1M
        </button>
        &nbsp;
        <button id="six_months"

          onClick={() => updateData('six_months')} className={(state.selection === 'six_months' ? 'active' : '')}>
          6M
        </button>
        &nbsp;
        <button id="one_year"


          onClick={() => updateData('one_year')} className={(state.selection === 'one_year' ? 'active' : '')}>
          1Y
        </button>
        &nbsp;
        <button id="ytd"

          onClick={() => updateData('ytd')} className={(state.selection === 'ytd' ? 'active' : '')}>
          YTD
        </button>
        &nbsp;
        <button id="all"

          onClick={() => updateData('all')} className={(state.selection === 'all' ? 'active' : '')}>
          ALL
        </button>
      </div>

      <div id="chart-timeline">
        <Chart options={state.options} series={state.series} type="area" height={300} />
      </div>
    </div>

  )
}


export default OverviewChart;
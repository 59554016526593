import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tradeApi from "../../app/api/tradeApi";

// const tradeData = getLocalStorage('tradeData');
interface TradeData {
  symbol: "";
  transaction: "";
  date: "";
  time: "";
  shares: "";
  price: "";
  commission: "";
  fees: "";
  broker: "";
}
const tradeData: TradeData[] = [];

const initialState = {
  tradeData: tradeData || [],
  stats: {
    firstTradeDate: new Date(),
    dailyPnl: [],
    accumDailyPnl: [],
  },
  initialFetch: false,
  isLoading: false,
  isSuccess: false,
  isError: false,
  showDeleteModal: false,
  showDeleteAllModal: false,
  deleteItem: [] as Array<string> | Array<number>,
  isDeleteLoading: false,
  isDeleteSuccess: false,
  isDeleteError: false,
  isAddLoading: false,
  isAddSuccess: false,
  isAddError: false,
  isImportLoading: false,
  isImportSuccess: false,
  isImportError: false,
  showAddTradeModal: false,
  showImportTradeModal: false,
  msg: "",
  sort: "dateDesc",
};
// Get User Trades
export const fetchTrade = createAsyncThunk(
  "/trades",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await tradeApi.fetchTrade(payload.pg, payload.accId);
      console.log(response);
      if (response.status < 200 || response.status >= 300) {
        return rejectWithValue(response.data);
      } else if (response.data?.error) {
        return rejectWithValue(response.data.error);
      }
      return response.data;
    } catch (err: any) {
      const msg =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return rejectWithValue(msg);
    }
  }
);
// Add Manual Trade
export const addTrade = createAsyncThunk(
  "/add/trades",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await tradeApi.manualTrade(payload);
      if (response?.status < 200 || response?.status >= 300) {
        return rejectWithValue(response?.data);
      } else if (response.data?.error) {
        return rejectWithValue(response.data.error);
      }
      return response.data;
    } catch (err: any) {
      const msg =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return rejectWithValue(msg);
    }
  }
);
// Import Trade
export const importTrade = createAsyncThunk(
  "/import/trades",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await tradeApi.importTrade(payload);
      if (response?.status < 200 || response?.status >= 300) {
        return rejectWithValue(response?.data);
      } else if (response.data?.error) {
        return rejectWithValue(response.data.error);
      }
      return response.data;
    } catch (err: any) {
      const msg =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return rejectWithValue(msg);
    }
  }
);
// Delete Trades
export const deleteTrades = createAsyncThunk(
  "/delete/trades",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await tradeApi.deleteTrade(payload);
      if (response && (response.status < 200 || response.status >= 300)) {
        return rejectWithValue(response.data);
      } else if (response?.data?.error) {
        return rejectWithValue(response.data.error);
      }
      return response?.data;
    } catch (err: any) {
      const msg =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return rejectWithValue(msg);
    }
  }
);

export const tradeSlice = createSlice({
  name: "trade",
  initialState,
  reducers: {
    resetState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.msg = "";
      state.isAddLoading = false;
      state.isAddSuccess = false;
      state.isAddError = false;
      state.isImportLoading = false;
      state.isImportSuccess = false;
      state.isImportError = false;
    },
    showDeleteModal: (state, action: any) => {
      state.showDeleteModal = true;
      state.deleteItem = action.payload;
    },
    showDeleteAllModal: (state, action: any) => {
      state.showDeleteAllModal = true;
      state.deleteItem = action.payload;
    },
    resetDelete: (state) => {
      state.isDeleteLoading = false;
      state.isDeleteSuccess = false;
      state.isDeleteError = false;
      state.showDeleteModal = false;
      state.showDeleteAllModal = false;
      state.deleteItem = [];
      state.msg = "";
      state.isError = false;
    },
    showAddTradeModal: (state, action: any) => {
      state.showAddTradeModal = action.payload;
    },
    showImportTradeModal: (state, action: any) => {
      state.showImportTradeModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Register User
      .addCase(fetchTrade.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTrade.fulfilled, (state, action) => {
        state.initialFetch = true;
        state.isSuccess = true;
        state.isLoading = false;
        state.tradeData = action.payload.trades;
        state.stats = action.payload.stats || initialState.stats;
        state.msg = action.payload.message;
        state.sort = action.payload.sort;
      })
      .addCase(fetchTrade.rejected, (state, action: any) => {
        state.isError = true;
        state.isLoading = false;
        state.msg = action.payload;
        state.tradeData = initialState.tradeData;
      })
      .addCase(deleteTrades.pending, (state, action: any) => {
        state.isDeleteLoading = true;
      })
      .addCase(deleteTrades.fulfilled, (state, action: any) => {
        state.isDeleteSuccess = true;
        state.isDeleteLoading = false;
        state.showDeleteModal = false;
        state.showDeleteAllModal = false;
      })
      .addCase(deleteTrades.rejected, (state, action: any) => {
        state.isDeleteError = true;
        state.isDeleteLoading = false;
        state.showDeleteModal = false;
        state.showDeleteAllModal = false;
        state.msg = action.payload.error;
      })
      .addCase(addTrade.pending, (state, action: any) => {
        state.isAddLoading = true;
      })
      .addCase(addTrade.fulfilled, (state, action: any) => {
        state.isAddSuccess = true;
        state.isAddLoading = false;
      })
      .addCase(addTrade.rejected, (state, action: any) => {
        state.isAddError = true;
        state.isAddLoading = false;
        state.msg = action.payload.error;
      })
      .addCase(importTrade.pending, (state, action: any) => {
        state.isImportLoading = true;
      })
      .addCase(importTrade.fulfilled, (state, action: any) => {
        state.isImportSuccess = true;
        state.isImportLoading = false;
      })
      .addCase(importTrade.rejected, (state, action: any) => {
        state.isImportError = true;
        state.isImportLoading = false;
        state.msg = action.payload.error;
      });
  },
});

export const {
  resetState,
  showDeleteModal,
  resetDelete,
  showAddTradeModal,
  showImportTradeModal,
  showDeleteAllModal,
} = tradeSlice.actions;
export default tradeSlice.reducer;

import axios from '../lib/axios';
import { removeLocalStorage } from '../hooks/useLocalStorage'

const USER_LOGOUT_API = process.env.REACT_APP_USER_LOGOUT_API as string;

export const isCookieEnabled = () => {
  const cookieEnabled = navigator.cookieEnabled;
  if (!cookieEnabled) console.log('Cookies Disabled');
  
  return cookieEnabled;
}

export const logOut = async () => {
    removeLocalStorage('user');
    removeLocalStorage('a_token');
    await axios.get(USER_LOGOUT_API).then(res=> {
        window.location.href = '/login'
    })
}

import { Outlet, useNavigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { checkUserAuth } from "../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Footer from "../components/Footer";

// For pages logged in
export const RequireAuth = () => {
  const { isLoggedIn } = useAppSelector((state: any) => state.auth);
  const dispatch = useDispatch()<any>;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(checkUserAuth()).then((res: any) => {
        console.log(res.payload.isLoggedIn);
        if (!res?.payload?.isLoggedIn) {
          navigate("/login", { replace: true });
        }
      });
    }
  }, [isLoggedIn]);

  return isLoggedIn ? <Outlet /> : <div></div>;
};
// For pages NOT logged in
export const CheckAuth = () => {
  const { isLoggedIn } = useAppSelector((state: any) => state.auth);
  const dispatch = useDispatch()<any>;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoggedIn) navigate("/dashboard", { replace: true });
    else {
      navigate("/login", { replace: true });
      dispatch(checkUserAuth()).then((res: any) => {
        if (res?.payload?.isLoggedIn) {
          navigate("/dashboard", { replace: true });
        } else {
          // navigate("/login", { replace: true });
        }
      });
    }
  }, [isLoggedIn]);
  return (
    <>
      {!isLoggedIn ? (
        <>
          <Outlet />
          {/* <Footer /> */}
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDispatch } from "react-redux"
import { useAppSelector } from "../../../../app/hooks";
import { deleteTrades, resetDelete } from "../../tradeSlice";

const DeleteModal = () => {
    const dispatch = useDispatch()<any>;
    const { showDeleteModal, showDeleteAllModal, deleteItem } = useAppSelector((state: any) => state.trade)
    const css_modal = {
        height: '300px',
        width: '400px',
        backgroundColor: 'white',
        margin: 'auto',
        zIndex: '999',
        top: 'calc(50% - 200px)',
        left: 'calc(50% - 150px)',
        display: 'flex',
        border: '2px solid black'
    }

    const handleYes = () => {
        console.log(deleteItem)
        dispatch(deleteTrades(deleteItem))
    }
    const handleNo = () => {
        dispatch(resetDelete())
    }

  return (
    <>
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showDeleteModal} onHide={handleNo}
        >
            <Modal.Header closeButton>
                <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 style={{textAlign: 'center'}}>Are you sure you want to delete?</h4>
            </Modal.Body>
            <Modal.Footer style={{justifyContent: 'center'}}>
                <Button variant="primary" onClick={handleYes}>
                    Yes
                </Button>
                <Button variant="danger" onClick={handleNo}>
                    No
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showDeleteAllModal} onHide={handleNo}
        >
            <Modal.Header closeButton>
                <Modal.Title>Delete All</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 style={{textAlign: 'center'}}>Are you sure you want to delete all trades?</h4>
            </Modal.Body>
            <Modal.Footer style={{justifyContent: 'center'}}>
                <Button variant="primary" onClick={handleYes}>
                    Yes
                </Button>
                <Button variant="danger" onClick={handleNo}>
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}

export default DeleteModal
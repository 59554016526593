import React from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { checkUserAuth } from '../authSlice';
import { FcGoogle } from 'react-icons/fc'

// Google Auth Success Page
export const GoogleAuthSuccessPage = () => {
    useEffect(()=> {
        window.close();
    },[])
    return <></>
}

// Google Button
export function GoogleButton() {
    const dispatch = useDispatch() as any;

    let googleWindowWidth = 500;
    let googleWindowHeight = 600;

    function openGoogle() {
        const url = process.env.REACT_APP_GOOGLE_AUTH_URL;
        const googleWindow = window.open(
            url, '_blank', `width=${googleWindowWidth}, height=${googleWindowHeight}`
        )
        if (!googleWindow) {
            alert('Please disable your popup blocker to login with Google.')
        } else if (googleWindow) {
            const timer = setInterval(() => {
                if (googleWindow.closed) {
                    if (timer) clearInterval(timer);
                    dispatch(checkUserAuth())
                }
            }, 500)
        }
    }

    
    return (
        <>
            <button className='login__google-btn' onClick={()=> openGoogle()}>
                <FcGoogle size={30} /> <b>GOOGLE</b>
            </button>
        </>
    )
}

import { useEffect, useState } from 'react';
import { registerUser } from '../../features/auth/authSlice';
import { useAppSelector } from '../../app/hooks';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import authApi from '../../app/api/authApi';
import { GoogleButton } from '../../features/auth/components/Google';
import { BlackLogoHeading } from '../../features/auth/components/LogoHeading';

const Register = () => {
    const [state, setState] = useState({
        email: '',
        password: '',
        passwordConfirmation: '',
        error: '',
        success: '',
        buttonText: 'Register'
    });
    const { email, password, passwordConfirmation, error, success, buttonText } = state;
    const { user, isLoading, isSuccess, isError, msg } = useAppSelector((state: any) => state.auth)
    const LOGIN_URL = process.env.REACT_APP_LOGIN_URL as string;
    
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    
    // Login after successful register
    useEffect(() => {
        const handleLogin = async () => {
            const payload = {
                email,
                password
            }
            try {
                const response = await authApi.loginUser(payload)
                if (response && response.data && !response.data.error) {
                    navigate('/dashboard')
                }
            } catch (err) {
                console.log(err)
            }
        }

        if (isError) {
            setState({ ...state, buttonText: 'Register', error: msg });
            
        }
        if (isLoading) setState({ ...state, buttonText: 'Registering' });
        if (isSuccess) {
            setState({ ...state, buttonText: 'Register' });
            handleLogin();
        }
    }, [user, isSuccess, isError, msg, dispatch, isLoading, email, password, navigate])

    // Handle user input changes
    const handleChange = (firstName: string) => (e: any) => {
        setState({ ...state, [firstName]: e.target.value, error: '', success: '', buttonText: 'Register' });
    };

    // Register Submit
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (isLoading) return;
        setState(prevState=>({...prevState, error: ''}))
        if (password !== passwordConfirmation) setState(prevState => ({ ...prevState, error: 'Passwords do not match'}))
        else {
            setState({ ...state, buttonText: 'Registering' });
 
            const userData = {
                email,
                password,
            }
            dispatch(registerUser(userData))
        }
    };

    // HTML Register Form
    const registerForm = () => (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <input
                    value={email}
                    onChange={handleChange('email')}
                    type="email"
                    className="form-control"
                    placeholder="Type your email"
                    autoComplete='off'
                    autoFocus
                />
            </div>
            <div className="form-group">
                <input
                    value={password}
                    onChange={handleChange('password')}
                    type="password"
                    minLength={6}
                    className="form-control"
                    placeholder="Type your password"
                    autoComplete='off'
                />
            </div>
            <div className="form-group">
                <input
                    value={passwordConfirmation}
                    onChange={handleChange('passwordConfirmation')}
                    type="password"
                    minLength={6}
                    className="form-control"
                    placeholder="Confirm your password"
                    autoComplete='off'
                />
            </div>
            <div className="form-group">
                <button className="btn btn-outline-warning login__form-btn">{buttonText}</button>
            </div>
        </form>
    );

    return (
        <>
         <div className='Main__login'>
            <div className='login__header'>
                <BlackLogoHeading />
                <h3>Register for a magical account</h3>
            </div>
            <div className='login__form'>
                <div className='login__error'>
                    {success && success}
                    {error && error}
                </div>
                {registerForm()}
            </div>
            <div className='login__divider-wrap'>
                <span className='login__divider-text'>or</span>
            </div>
            <div className='sso__container'>
                <GoogleButton />
            </div>
            
            <div className='login__signup'>
                Already have an account? <Link to ={LOGIN_URL}>Sign In</Link>
            </div>
        </div>
        </>
    );
};

export default Register;

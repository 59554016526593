import Axios from  "axios";
import { isCookieEnabled, logOut } from "../helper/auth";
import { getLocalStorage } from '../hooks/useLocalStorage'

let axios = Axios.create({ withCredentials: true });
const REGISTER_URL = process.env.REACT_APP_REGISTER_URL as string;
const LOGIN_URL = process.env.REACT_APP_LOGIN_URL as string;
const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL as string;
const COOKIES_URL = process.env.REACT_APP_COOKIES_URL as string;

axios.defaults.withCredentials = true;

axios.interceptors.request.use((req : any) => {
  const ACCESS_TOKEN = getLocalStorage('a_token');
  const USER = getLocalStorage('user');
  const auth = {
    userToken: ACCESS_TOKEN,
    email:USER.email
  }

  req.headers.authorization = JSON.stringify(auth);
  return req;
});

axios.interceptors.response.use(
  (response: any) => {
    const win: Window = window;
    if (!isCookieEnabled()) win.location = COOKIES_URL
    else if (response.status === 200 && response.data && response.data.isLoggedIn === false) {
      // removeLocalStorage('user');
      if (win.location.pathname === DASHBOARD_URL) logOut();
      else if (win.location.pathname === '/') win.location = LOGIN_URL;
    }
    return response;
  }, 
  (error) => {
      const win: Window = window;
      if (error?.response?.status === 401) {
        // removeLocalStorage('user');
        if (win.location.pathname !== LOGIN_URL && win.location.pathname !== REGISTER_URL) win.location = LOGIN_URL;
      }
      else if (error?.response?.status === 400) {
        if (error.response.data.isLoggedIn === false) {
          logOut();
        }
        return error.response;
      }
      throw new Error(error);
  }
);

export default axios;
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "rsuite/dist/rsuite.min.css";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GoogleAuthSuccessPage } from "./features/auth/components/Google";
import Register from "./pages/register/Register";
// import Verify from './pages/register/Verify';
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import { RequireAuth, CheckAuth } from "./hooks/useCheckAuth";
import Layout from "./components/Layout";
import ResetPassword from "./pages/password/ResetPassword";
import ForgotPassword from "./pages/password/ForgotPassword";
import Cookies from "./pages/Cookies";
import { Page404 } from "./pages/Page404";
import TradeDetail from "./pages/tradeDetail/TradeDetail";
import TradeCalendar from "./pages/tradeCalendar/TradeCalendar";

function App() {
  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Running Development");
    else console.log("Running Production");
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Private */}
          <Route element={<RequireAuth />}>
            <Route path="/dashboard" element={<Layout />}>
              <Route index element={<Dashboard />} />
            </Route>
            <Route path="/trades" element={<Layout />}>
              <Route index element={<Dashboard />} />
              <Route path="detail">
                <Route index element={<Dashboard />} />
                <Route path=":id" element={<TradeDetail />} />
              </Route>
              <Route path="calendar">
                <Route index element={<TradeCalendar />} />
              </Route>
            </Route>
          </Route>
          {/* Public */}
          <Route path="/" element={<CheckAuth />}>
            {/* <Route path='/' element={ <Landing />} /> */}
            <Route path="register" element={<Register />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
          </Route>
          {/* <Route path="auth/verify/:token" element={ <Verify />} /> */}

          <Route
            path="auth/password/reset/:resetPasswordLink"
            element={<ResetPassword />}
          />

          {/* Re-routes */}
          <Route
            path="/auth/google/success"
            element={<GoogleAuthSuccessPage />}
          />

          {/* 404 */}
          <Route path="*" element={<Page404 />} />

          <Route path="/cookies" element={<Cookies />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import isoWeek from "dayjs/plugin/isoWeek";
import "./tradeCalendar.scss";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { fetchTrade } from "../../features/dashboard/tradeSlice";

dayjs.extend(weekday);
dayjs.extend(isoWeek);

interface Trade {
  closedDate: string;
  pnl: number;
  transaction: string;
  symbol: string;
}

interface DayPnl {
  date: string;
  pnl: number;
  tradesCount: number;
}

interface Calendar {
  [key: string]: DayPnl[];
}

const TradeCalendar: React.FC = () => {
  const { config } = useAppSelector((state: any) => state.user);
  const { isLoading, isSuccess, tradeData } = useAppSelector(
    (state: any) => state.trade
  );
  const dispatch = useDispatch()<any>;
  const { defaultAccount } = config;

  // Fetch Trade Data
  useEffect(() => {
    if (defaultAccount) {
      dispatch(fetchTrade({ pg: 1, accId: defaultAccount }));
    }
  }, [defaultAccount, dispatch]);

  const [calendar, setCalendar] = useState<Calendar>({});
  const [monthlySummary, setMonthlySummary] = useState<{
    [key: string]: number;
  }>({});
  const [yearlyPnl, setYearlyPnl] = useState<number>(0);

  useEffect(() => {
    if (isSuccess) {
      generateCalendar();
    }
  }, [tradeData, isSuccess]);

  const generateCalendar = () => {
    const startOfYear = dayjs().startOf("year");
    const today = dayjs();
    const days: dayjs.Dayjs[] = [];

    for (
      let day = startOfYear;
      day.isBefore(today) || day.isSame(today);
      day = day.add(1, "day")
    ) {
      days.push(day);
    }

    const dailyPnl: DayPnl[] = days.map((day) => {
      const dayStr = day.format("YYYYMMDD");
      const tradesOfDay = tradeData.filter(
        (trade: Trade) =>
          dayjs(String(trade.closedDate), "YYYYMMDDHHmmss").format(
            "YYYYMMDD"
          ) === dayStr
      );
      const totalPnl = tradesOfDay.reduce(
        (sum: number, trade: Trade) => sum + trade.pnl,
        0
      );
      return {
        date: dayStr,
        pnl: totalPnl,
        tradesCount: tradesOfDay.length,
      };
    });

    const groupedByMonth = dailyPnl.reduce((acc: Calendar, day) => {
      const month = dayjs(day.date).format("MMMM YYYY");
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(day);
      return acc;
    }, {});

    // Sorting the calendar months in descending order and filtering out future months
    const sortedCalendar = Object.keys(groupedByMonth)
      .filter(
        (month) =>
          dayjs(month, "MMMM YYYY").isBefore(today) ||
          dayjs(month, "MMMM YYYY").isSame(today, "month")
      )
      .sort((a, b) => dayjs(b, "MMMM YYYY").diff(dayjs(a, "MMMM YYYY")))
      .reduce((acc, key) => {
        acc[key] = groupedByMonth[key];
        return acc;
      }, {} as Calendar);

    setCalendar(sortedCalendar);

    const monthlyPnlSummary = dailyPnl.reduce(
      (acc: { [key: string]: number }, day) => {
        const month = dayjs(day.date).format("MMMM YYYY");
        if (!acc[month]) {
          acc[month] = 0;
        }
        acc[month] += day.pnl;
        return acc;
      },
      {}
    );

    setMonthlySummary(monthlyPnlSummary);

    const totalYearlyPnl = dailyPnl.reduce(
      (acc: number, day) => acc + day.pnl,
      0
    );

    setYearlyPnl(totalYearlyPnl);
  };

  return (
    <Container>
      <Row className="mt-4">
        <Col md={12}>
          <Card className="tradeCard-boxShadow">
            <Card.Header as="h5" className="calendar-header">
              Monthly P&L Summary
            </Card.Header>
            <Card.Body className="calendar-body">
              <Table bordered>
                <thead>
                  <tr>
                    {Object.keys(monthlySummary).map((month) => (
                      <th key={month} style={{ textAlign: "center" }}>
                        {month}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {Object.keys(monthlySummary).map((month) => (
                      <td key={month} className="calendar-cell">
                        <div
                          className={`pnl ${getPnlClass(
                            monthlySummary[month]
                          )}`}
                        >
                          ${monthlySummary[month].toFixed(2)}
                        </div>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
              <div style={{ fontWeight: "bold" }}>
                Yearly P&L:{" "}
                <span className={`pnl ${getPnlClass(yearlyPnl)}`}>
                  ${yearlyPnl.toFixed(2)}
                </span>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        {Object.keys(calendar).map((month) => (
          <React.Fragment key={month}>
            <Col md={12} className="mb-4">
              <Card className="mb-4 tradeCard-boxShadow">
                <Card.Header as="h5" className="calendar-header">
                  {month}
                </Card.Header>
                <Card.Body className="calendar-body">
                  <Table bordered>
                    <thead>
                      <tr>
                        {[
                          "Sun",
                          "Mon",
                          "Tue",
                          "Wed",
                          "Thu",
                          "Fri",
                          "Sat",
                          "Total",
                        ].map((day) => (
                          <th key={day} style={{ textAlign: "center" }}>
                            {day}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {generateCalendarRows(calendar[month], month)}
                    </tbody>
                  </Table>
                  <div style={{ fontWeight: "bold" }}>
                    Monthly P&L:{" "}
                    <span
                      className={`pnl ${getPnlClass(monthlySummary[month])}`}
                    >
                      ${monthlySummary[month].toFixed(2)}
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </Container>
  );

  function generateCalendarRows(days: DayPnl[], month: string) {
    const monthStart = dayjs(days[0].date).startOf("month");
    const monthEnd = dayjs(days[0].date).endOf("month");
    const firstDayOfWeek = monthStart.day();
    const totalDays = monthEnd.date();

    const calendarRows = [];
    let row = new Array(firstDayOfWeek).fill(null); // Fill the first row with null until the first day of the month
    let weeklyPnl = 0;

    for (let i = 1; i <= totalDays; i++) {
      const day = days.find((d) => dayjs(d.date).date() === i);
      row.push(
        day || {
          date: monthStart.date(i).format("YYYYMMDD"),
          pnl: 0,
          tradesCount: 0,
        }
      );
      weeklyPnl += day ? day.pnl : 0;

      if (row.length === 7) {
        row.push({ date: "", pnl: weeklyPnl, tradesCount: 0 });
        calendarRows.push(row);
        row = [];
        weeklyPnl = 0;
      }
    }

    if (row.length > 0) {
      row = row.concat(new Array(7 - row.length).fill(null)); // Fill the last row with null until the end of the week
      row.push({ date: "", pnl: weeklyPnl, tradesCount: 0 });
      calendarRows.push(row);
    }

    return calendarRows.map((week, idx) => (
      <tr key={idx}>
        {week.map((day: DayPnl | null, dayIdx: number) => (
          <td key={dayIdx} className="calendar-cell">
            {day && (
              <>
                {day.date ? (
                  <>
                    <div className="calendar-date">
                      {dayjs(day.date).format("D")}
                    </div>
                    <div className={`pnl ${getPnlClass(day.pnl)}`}>
                      {day.pnl ? "$" + day.pnl.toFixed(2) : "—"}
                    </div>
                    <div
                      style={
                        day.tradesCount === 0
                          ? { visibility: "hidden" }
                          : { visibility: "visible" }
                      }
                    >
                      {day.tradesCount} trades
                    </div>
                  </>
                ) : (
                  <div className={`pnl ${getPnlClass(day.pnl)}`}>
                    ${day.pnl.toFixed(2)}
                  </div>
                )}
              </>
            )}
          </td>
        ))}
      </tr>
    ));
  }

  function getPnlClass(pnl: number) {
    if (pnl > 0) return "positive";
    if (pnl < 0) return "negative";
    return "zero";
  }
};

export default TradeCalendar;

import React from 'react'
import whiteLogo from '../../../assets/images/logo_white.png'
import blackLogo from '../../../assets/images/logo_black.png'
import './logoHeading.scss'

export const WhiteLogoHeading = React.memo(() => {
  return (
    <div className='c-auth__logo-heading'>
      <a style={{textDecoration: 'none', color: 'black'}} href='/'><img className='c-logo__heading-img' src={whiteLogo} alt="logo" /> <h2>Magic Trade Journal</h2></a>
    </div>
  )
})

export const BlackLogoHeading = React.memo(() => {
  return (
    <div className='c-auth__logo-heading'>
      <a style={{textDecoration: 'none', color: 'black'}} href='/'><img className='c-logo__heading-img' src={blackLogo} alt="logo" /> <h2>Magic Trade Journal</h2></a>
    </div>
  )
})


import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import logo from "../assets/images/logo_white.png";
import {
  showAddTradeModal,
  showImportTradeModal,
} from "../features/dashboard/tradeSlice";
import { logOut } from "../helper/auth";
import "./navbar.scss";
import EnterTrade from "../features/dashboard/components/EnterTrade";
import ImportTrade from "../features/dashboard/components/ImportTrade";

function DashboardNavbar() {
  const { user } = useAppSelector((state: any) => state.user);

  const dispatch = useDispatch()<any>;

  const handleAdd = () => {
    dispatch(showAddTradeModal(true));
  };
  const handleImport = () => {
    dispatch(showImportTradeModal(true));
  };
  const location = useLocation();
  const path = location.pathname;
  const isDashboard =
    path === "/dashboard" ? { color: "black" } : { color: "grey" };
  const isCalendar =
    path === "/trades/calendar" ? { color: "black" } : { color: "grey" };
  return (
    <Navbar
      style={{ borderBottom: "1px solid #bababa" }}
      bg="white"
      expand="md"
    >
      <EnterTrade />
      <ImportTrade />
      <Container fluid>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Navbar.Brand className="navbar-logo-header">
            <img
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
            <span style={{ marginLeft: ".5em" }}>Magic Trade Journal</span>
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="esponsive-navbar-nav" />
        <Navbar.Collapse id="esponsive-navbar-nav">
          <Nav className="me-auto my-2 my-lg-0">
            <Nav.Link href="/dashboard">
              <b style={isDashboard}>
                {user.firstName
                  ? `${user.firstName}'s Dashboard`
                  : "My Dashboard"}
              </b>
            </Nav.Link>
            <Nav.Link href="/trades/calendar">
              <b style={isCalendar}>Calendar</b>
            </Nav.Link>
            <Nav.Link onClick={handleAdd}>
              <b>Add Trades</b>
            </Nav.Link>
            <Nav.Link onClick={handleImport}>
              <b>Import Trades</b>
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                logOut();
              }}
            >
              <b>Sign out</b>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default DashboardNavbar;

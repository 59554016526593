import { BiImport, BiPlus } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../app/hooks";
import { showAddTradeModal, showImportTradeModal } from '../features/dashboard/tradeSlice'
import './sideMenu.scss'

function SideMenu() {

    const { tradeData, initialFetch } = useAppSelector((state: any) => state.trade)

    const dispatch = useDispatch()<any>;

    const handleAdd = () => {
        dispatch(showAddTradeModal(true))
    }
    const handleImport = () => {
        dispatch(showImportTradeModal(true))
    }

  return (
    <>
    {initialFetch ? 
    <div className={tradeData.length ? "sideMenu-container" : 'sideMenu-container sideMenu-container--no-data'}>
        {!tradeData.length 
            ? <div className="sideMenu__header--no-data"><h1 style={{color:'black'}}>Add Your First Trade</h1></div> 
            : ''
        }
        <div className="sideMenu">
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div onClick={handleAdd} className="sideMenu__button sideMenu__button--add">
                    <BiPlus size={30}/>
                    Add Trade
                </div>
                <div onClick={handleImport} className="sideMenu__button sideMenu__button--import">
                    <BiImport size={30}/>
                    Import
                </div>
            </div>

        </div>

    </div>
    : ''}
    </>
  );
}

export default SideMenu;
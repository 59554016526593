import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import NumberFormat from "react-number-format";
import { withPriceLimit, withSharesLimit } from "../util";
import { DatePicker } from "rsuite";
import { useAppSelector } from "../../../app/hooks";
import { useDispatch } from "react-redux";
import {
  addTrade,
  resetState,
  showAddTradeModal as showAddModal,
} from "../tradeSlice";
import { BiPlus } from "react-icons/bi";
import Spinner from 'react-bootstrap/Spinner';
import "./enterTrade.scss";

dayjs.extend(utc);

const EnterTrade = () => {
  const DATA = {
    accId: "",
    status: "",
    symbol: "",
    transaction: "",
    date: new Date(),
    time: null as null | Date,
    shares: "" as string | number,
    price: "" as string | number,
    commission: "" as string | number,
    fees: "" as string | number,
    broker: "",
    dateTime: 0 as number,
  };

  const [state, setState] = useState({
    error: "",
    success: "",
    buttonText: "SAVE",
  });

  const { config } = useAppSelector((state: any) => state.user);
  const { isAddSuccess, isAddLoading, showAddTradeModal } = useAppSelector(
    (state: any) => state.trade
  );

  const [tradeData, setTradeData] = useState(DATA);
  const [position, setPosition] = useState("open");
  const [isAddMore, setIsAddMore] = useState(false);

  const { error, success, buttonText } = state;
  const dispatch = useDispatch()<any>;

  const handleHideAddModal = () => {
    dispatch(showAddModal(false));
  };

  // Trade add success
  useEffect(() => {
    if (isAddSuccess) {
      if (!isAddMore) dispatch(showAddModal(false));
      setTradeData(DATA);
      dispatch(resetState());
    }
  }, [isAddSuccess]);

  // Handle Trade Submit
  const handleSubmit = async (e?: any) => {
    if (e) e.preventDefault();
    const { status, time, date, ...formData } = tradeData;
    // if (isAddLoading || !tradeData.time) return;
    if (isAddLoading || !time) return;

    console.log("submitting");

    if (formData.commission === "") formData.commission = 0;
    if (formData.fees === "") formData.fees = 0;
    let newTime = dayjs(time);
    let newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      newTime.hour(),
      newTime.minute(),
      newTime.second()
    );
    let finalDate = dayjs(newDate);

    formData.dateTime = Number.parseInt(finalDate.format("YYYYMMDDHHmmss"));
    formData.accId = config?.defaultAccount;
    dispatch(addTrade(formData));
  };
  // Handle regular input changes
  const handleChange =
    (input: string) => (e: { target: { value: string } }) => {
      setTradeData({ ...tradeData, [input]: e.target.value });
    };
  // Handle trade position change
  // const handlePositionChange = (e: { target: { value: string; }; }) => {
  //   setPosition(e.target.value);
  // };
  // Handle changes for NumberFormat
  const handleValueChange = (values: any, input: any) => {
    const { value } = values;
    setTradeData({ ...tradeData, [input]: Number(value) });
  };
  // Handle Date Change
  const handleDateChange = (date: any) => {
    console.log(date);
    setTradeData({ ...tradeData, date: date });
  };
  const handleTimeChange = (time: any) => {
    setTradeData({ ...tradeData, time: time });
  };
  const handleAddMore = () => {
    setIsAddMore(true);
    handleSubmit();
  };

  const OpenTradeForm = () => {
    return (
      <>
        <form noValidate onSubmit={handleSubmit} style={{ padding: "1em" }}>
          {/* Symbol */}
          <div className="enter-trade__form-input-wrap">
            <div className="form-group">
              <label className="form-label" htmlFor="symbol">
                Symbol
              </label>
              <input
                id="symbol"
                value={tradeData.symbol}
                onChange={handleChange("symbol")}
                type="text"
                className="form-control"
                placeholder="e.g. ABCD"
                autoFocus
              />
            </div>
          </div>
          {/* Transaction */}
          <div className="enter-trade__form-input-wrap">
            <div className="form-group">
              <label className="form-label" htmlFor="transaction">
                Transaction
              </label>
              <select
                value={tradeData.transaction}
                onChange={handleChange("transaction")}
                name="transaction"
                id="transaction"
                className="form-select"
              >
                <option value="" disabled>
                  Type of Transaction
                </option>
                <option value="buy">BUY</option>
                <option value="sell">SELL</option>
              </select>
            </div>
          </div>
          {/* Price */}
          <div className="enter-trade__form-input-wrap">
            <div className="form-group">
              <label className="form-label" htmlFor="price">
                Price
              </label>
              <NumberFormat
                value={tradeData.price}
                id="price"
                onValueChange={(values: any) => {
                  handleValueChange(values, "price");
                }}
                type="tel"
                className="form-control"
                placeholder="$0.00"
                prefix={"$"}
                displayType={"input"}
                thousandSeparator={true}
                allowNegative={false}
                decimalScale={20}
                isAllowed={withPriceLimit}
              />
            </div>
          </div>
          {/* Shares */}
          <div className="enter-trade__form-input-wrap">
            <div className="form-group">
              <label className="form-label" htmlFor="shares">
                Shares
              </label>
              <NumberFormat
                id="shares"
                value={tradeData.shares}
                onValueChange={(values: any) => {
                  handleValueChange(values, "shares");
                }}
                type="tel"
                className="form-control"
                placeholder="0"
                allowNegative={false}
                thousandSeparator={true}
                decimalScale={0}
                isAllowed={withSharesLimit}
              />
            </div>
          </div>
          {/* Date */}
          <div className="enter-trade__form-input-wrap">
            <label className="form-label" htmlFor="transaction">
              Date & Time
            </label>
            <div className="input-group">
              <DatePicker
                oneTap
                value={tradeData.date}
                onChange={handleDateChange}
                style={{ width: "100%" }}
                format="MM/dd/yyyy"
                placeholder="Select Date"
              />
            </div>
            <DatePicker
              format="HH:mm:ss"
              ranges={[]}
              value={tradeData.time}
              onOk={handleTimeChange}
              onSelect={handleTimeChange}
              placeholder="Select Time"
            />
            <div className="invalid-feedback">Please choose a username.</div>
          </div>

          {/* Commission */}
          <div className="enter-trade__form-input-wrap">
            <div className="form-group">
              <label className="form-label" htmlFor="commission">
                Commission
              </label>
              <NumberFormat
                value={tradeData.commission}
                id="commission"
                onValueChange={(values: any) => {
                  handleValueChange(values, "commission");
                }}
                type="tel"
                className="form-control"
                placeholder="$0.00"
                prefix={"$"}
                displayType={"input"}
                thousandSeparator={true}
                allowNegative={false}
                decimalScale={20}
                isAllowed={withPriceLimit}
              />
            </div>
          </div>
          {/* Fees */}
          <div className="enter-trade__form-input-wrap">
            <div className="form-group">
              <label className="form-label" htmlFor="fees">
                Fees
              </label>
              <NumberFormat
                value={tradeData.fees}
                id="fees"
                onValueChange={(values: any) => {
                  handleValueChange(values, "fees");
                }}
                type="tel"
                className="form-control"
                placeholder="$0.00"
                prefix={"$"}
                displayType={"input"}
                thousandSeparator={true}
                allowNegative={false}
                decimalScale={20}
                isAllowed={withPriceLimit}
              />
            </div>
          </div>
          {/* Broker */}
          <div className="enter-trade__form-input-wrap">
            <div className="form-group">
              <label className="form-label" htmlFor="transaction">
                Broker
              </label>
              <select
                value={tradeData.broker}
                onChange={handleChange("broker")}
                name="broker"
                id="broker"
                className="form-select"
              >
                <option value="" disabled>
                  Choose Your Broker
                </option>
                <option value="ib">Interactive Brokers</option>
                <option value="tda">TD Ameritrade</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
        </form>
      </>
    );
  };
  // TODO: Implement different ways to input trades
  // const ClosedTradeForm = () => {
  //   return (
  //     <>
  //       <form noValidate onSubmit={handleSubmit}>
  //         {/* Symbol */}
  //         <div className='enter-trade__form-input-wrap'>
  //           <div className="form-group">
  //             <label className='form-label' htmlFor="symbol">Symbol</label>
  //             <input
  //               id='symbol'
  //               value={tradeData.symbol}
  //               onChange={handleChange('symbol')}
  //               type="text"
  //               className="form-control"
  //               placeholder="e.g. ABCD"
  //               autoFocus
  //             />
  //           </div>
  //         </div>
  //         {/* Transaction */}
  //         <div className='enter-trade__form-input-wrap'>
  //           <div className="form-group">
  //             <label className='form-label' htmlFor="transaction">Transaction</label>
  //             <select  value={tradeData.transaction} onChange={handleChange('transaction')} name="transaction" id="transaction" className="form-select">
  //               <option value="" disabled>Type of Transaction</option>
  //               <option value="buy">BUY</option>
  //               <option value="sell">SELL</option>
  //             </select>
  //           </div>
  //         </div>
  //         {/* Price */}
  //         <div className='enter-trade__form-input-wrap'>
  //           <div className="form-group">
  //             <label className='form-label' htmlFor="price">Price</label>
  //             <NumberFormat
  //               value={tradeData.price}
  //               id='price'
  //               onValueChange={(values: any) => {
  //                 handleValueChange(values, 'price');
  //               }}
  //               type='tel'
  //               className='form-control'
  //               placeholder='$0.00'
  //               prefix={'$'}
  //               displayType={'input'}
  //               thousandSeparator={true}
  //               allowNegative={false}
  //               decimalScale={20}
  //               isAllowed={withPriceLimit}
  //             />
  //           </div>
  //         </div>
  //         {/* Shares */}
  //         <div className='enter-trade__form-input-wrap'>
  //           <div className="form-group">
  //             <label className='form-label' htmlFor="shares">Shares</label>
  //             <NumberFormat
  //               id='shares'
  //               value={tradeData.shares}
  //               onValueChange={(values: any) => {
  //                 handleValueChange(values, 'shares');
  //               }}
  //               type="tel"
  //               className="form-control"
  //               placeholder="0"
  //               allowNegative={false}
  //               thousandSeparator={true}
  //               decimalScale={0}
  //               isAllowed={withSharesLimit}
  //             />
  //           </div>
  //         </div>
  //         {/* Date */}
  //         <div className='enter-trade__form-input-wrap'>
  //           <label className='form-label' htmlFor="transaction">Date & Time</label>
  //           <div className="input-group">
  //             {/* <span className="input-group-text" id="inputGroupPrepend3"><BsCalendar2Date /></span> */}
  //             {/* <DatePicker
  //               className='form-control test'
  //               selected={tradeData.date}
  //               onChange={handleDateChange}
  //               placeholderText={'MM/DD/YYYY'}
  //             /> */}
  //               <DatePicker
  //               oneTap
  //               value={tradeData.date}
  //               onChange={handleDateChange}
  //               style={{ width: '100%'}}
  //               format="MM/dd/yyyy"
  //               placeholder="Select Date"
  //               />
  //           </div>
  //             <DatePicker
  //               format="HH:mm:ss"
  //               ranges={[]}
  //               onOk={handleTimeChange}
  //               onSelect={handleTimeChange}
  //               placeholder="Select Time"
  //             />
  //           {/* <div className='input-group__time'>
  //             <TimePicker
  //                 ampmInClock
  //                 openTo="hours"
  //                 views={['hours', 'minutes', 'seconds']}
  //                 inputFormat="HH:mm:ss"
  //                 mask="__:__:__"
  //                 label="Time"
  //                 value={tradeData.time}
  //                 onChange={handleTimeChange}
  //                 renderInput={(params) => <TextField {...params} />}
  //               />
  //           </div> */}
  //             <div className="invalid-feedback">
  //               Please choose a username.
  //             </div>
  //         </div>

  //         {/* Commission */}
  //         <div className='enter-trade__form-input-wrap'>
  //           <div className="form-group">
  //             <label className='form-label' htmlFor="commission">Commission</label>
  //             <NumberFormat
  //               value={tradeData.commission}
  //               id='commission'
  //               onValueChange={(values: any) => {
  //                 handleValueChange(values, 'commission');
  //               }}
  //               type='tel'
  //               className='form-control'
  //               placeholder='$0.00'
  //               prefix={'$'}
  //               displayType={'input'}
  //               thousandSeparator={true}
  //               allowNegative={false}
  //               decimalScale={20}
  //               isAllowed={withPriceLimit}
  //             />
  //           </div>
  //         </div>
  //         {/* Fees */}
  //         <div className='enter-trade__form-input-wrap'>
  //           <div className="form-group">
  //             <label className='form-label' htmlFor="fees">Fees</label>
  //             <NumberFormat
  //               value={tradeData.fees}
  //               id='fees'
  //               onValueChange={(values: any) => {
  //                 handleValueChange(values, 'fees');
  //               }}
  //               type='tel'
  //               className='form-control'
  //               placeholder='$0.00'
  //               prefix={'$'}
  //               displayType={'input'}
  //               thousandSeparator={true}
  //               allowNegative={false}
  //               decimalScale={20}
  //               isAllowed={withPriceLimit}
  //             />
  //           </div>
  //         </div>
  //         {/* Broker */}
  //         <div className='enter-trade__form-input-wrap'>
  //           <div className="form-group">
  //             <label className='form-label' htmlFor="transaction">Broker</label>
  //             <select value={tradeData.broker} onChange={handleChange('broker')} name="broker" id="broker" className="form-select">
  //               <option value="" disabled>Choose Your Broker</option>
  //               <option value="ib">Interactive Brokers</option>
  //               <option value="tda">TD Ameritrade</option>
  //               <option value="other">Other</option>
  //             </select>
  //           </div>
  //         </div>

  //         <div className="form-group enter-trade__save-btn">
  //           <button className="btn btn-outline-warning">{buttonText}</button>
  //         </div>
  //       </form>
  //     </>
  //   )
  // }

  return (
    <>
      <Modal centered show={showAddTradeModal} onHide={handleHideAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="modal__title sideMenu__button--add">
              <BiPlus size={30} />
              <span className="modal__title-text">Add Trade</span>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Status */}
          {/* <div className='enter-trade__form-input-wrap'>
            <div className="form-group">
              <label className='form-label' htmlFor="transaction">Position</label>
              <select value={position} onChange={handlePositionChange} id="transaction" className="form-select">
                <option value="open">OPEN</option>
                <option value="closed">CLOSED</option>
              </select>
            </div>
          </div> */}
          {position === "open"
            ? OpenTradeForm()
            : // : ClosedTradeForm()
              ""}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            {isAddLoading && !isAddMore ?  <Spinner
              as="span"
              animation="border"
              size="sm"
              variant="dark"
              role="status"
              aria-hidden="true"
            /> : buttonText}
            
          </Button>
          <Button style={{minWidth:'170px'}} variant="secondary" onClick={handleAddMore}>
          {isAddLoading && isAddMore ? <Spinner
              as="span"
              animation="border"
              size="sm"
              variant="dark"
              role="status"
              aria-hidden="true"
            /> : <span>Save & Add More</span>}
            
          </Button>
          <Button variant="danger" onClick={handleHideAddModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EnterTrade;

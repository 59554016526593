import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { GoogleButton } from '../../features/auth/components/Google';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../features/auth/authSlice';
import { useAppSelector } from '../../app/hooks';
import { WhiteLogoHeading } from '../../features/auth/components/LogoHeading';
import Spinner from 'react-bootstrap/Spinner';
import './login.scss'

export default function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch() as any;
    
    const [state, setState] = useState({
        email: '',
        password: '',
        error: '',
        success: '',
        buttonText: 'sign in'
    });

    const REGISTER_URL = process.env.REACT_APP_REGISTER_URL as string;
    const FORGOT_PASSWORD_URL = process.env.REACT_APP_FORGOT_PASSWORD_URL as string;
    
    const {email, password, error, success, buttonText } = state;
    const { isLoading, isSuccess, isError, msg } = useAppSelector((state: any) => state.auth)

    useEffect(() => {
        if (isError) {
            // alert(msg)
            setState({ ...state, buttonText: 'Sign in', error: msg });
            
        }
        if (isLoading) setState({ ...state, buttonText: 'Logging in' });
        if (isSuccess) {
            console.log("ff")
            setState({ ...state, buttonText: 'Login' });
            // navigate(DASHBOARD_URL, {replace: true})
        }
    },[dispatch, isError, isLoading, isSuccess, msg, navigate])


  
    const handleChange = (input: string) => (e: { target: { value: string; }; }) => {
        setState({ ...state, [input]: e.target.value, error: '', success: ''});
    };

    // Form Submit
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (isLoading) return;
        const payload = {
            email,
            password
        }
        dispatch(loginUser(payload));
    };

    const LoginForm = () => (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <input
                    value={email}
                    onChange={handleChange('email')}
                    type="email"
                    className="form-control"
                    placeholder="Type your email"
                    autoFocus
                />
            </div>
            <div className="form-group">
                <input
                    value={password}
                    onChange={handleChange('password')}
                    type="password"
                    minLength={6}
                    className="form-control"
                    placeholder="Type your password"
                />
            </div>
            {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow:'hidden',
            flexDirection:'column',
            textAlign:'center'
          }}
        >
          <Spinner
              as="span"
              animation="border"
              variant="dark"
              size='sm'
              role="status"
              aria-hidden="true"
            /> <br></br>
          Waking Up Server.<br></br>
          &#9749; Sorry For The Cold Start...
        </div>
      ) : (
        ""
      )}
            <div className="form-group">
                <button className="btn btn-outline-warning login__form-btn">{buttonText}</button>
            </div>
        </form>
    );

    const ForgotPassword = () => (
        <div className='login__signup'>
            <Link to={FORGOT_PASSWORD_URL} style={{textDecoration: 'none'}}>Forgot Password?</Link>
        </div>
    )

    const SignUp = () => (
        <div className='login__signup'>
            Don't have an account? <Link to={REGISTER_URL}>Sign Up</Link>
        </div>
    )
    

    return (
        <>
        <div className='Main__login'>
            <div className='login__header'>
                <WhiteLogoHeading />
                <h3>Sign in to Magic</h3>
            </div>
            <div className='login__form'>
                <div className='login__error'>
                    {success && success}
                    {error && error}
                </div>
                {/* <LoginForm /> */}
                {LoginForm()}
                <ForgotPassword />
                
            </div>
            <div className='login__divider-wrap'>
                <span className='login__divider-text'>or</span>
            </div>
            <div className='sso__container'>
                <GoogleButton />
            </div>
            <SignUp />

        </div>
        <div style={{marginTop:'2em'}}>
            Test Account: <br></br>
            Email: <b>magic@magic.com</b><br></br>
            Password: <b>magical</b>
        </div>
        </>
    )
}

import dayjs from "dayjs";
import BROKERS from "../../../helper/brokerData.json";

export const withPriceLimit = (floatValue: any) => {
  const MAX_VAL = 999999999999999;
  const { value } = floatValue;
  return value <= MAX_VAL;
};
export const withSharesLimit = (floatValue: any) => {
  const MAX_VAL = 9999999999999999999999999;
  const { value } = floatValue;
  return value <= MAX_VAL;
};

// Get date format to display: 'MM/DD/YYYY'
export const getDate = (date: string | number) => {
  return dayjs(date.toString().slice(0, 8)).format("MM/DD/YYYY");
};
// Get date format to display: 'MM/DD/YYYY'
export const getDateAndTime = (date: string | number) => {
  return dayjs(date.toString()).format("MM/DD/YYYY HH:mm:ss");
};
// Get date format to display: 'MM/DD/YYYY'
export const toServerDate = (date: string) => {
  return Number(dayjs(date).format("YYYYMMDDHHmmss"));
};

// Get Broker
export const getBroker = (broker: string) => {
  return BROKERS[broker as keyof typeof BROKERS] || "N/A";
};

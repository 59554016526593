import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import logo from '../../assets/images/logo_white.png'
import authApi from '../../app/api/authApi';
import { BlackLogoHeading } from '../../features/auth/components/LogoHeading';

const ResetPassword = () => {
    const [state, setState] = useState({
        newPassword: '',
        confirmNewPassword: '',
        error: '',
        success: '',
        buttonText: 'Reset Password'
    });
    const [isReset, setIsReset] = useState(false);
    const { newPassword, confirmNewPassword, error, success } = state;
    const { resetPasswordLink } = useParams();
    const navigate = useNavigate();
    const LOGIN_URL = process.env.REACT_APP_LOGIN_URL as string;


    const handleChange = (newPassword: string) => (e: { target: { value: any; }; }) => {
        setState({...state, [newPassword]: e.target.value, error: ''});
    };

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (newPassword !== confirmNewPassword) {
            setState({...state, error: 'Passwords do not match'})
            return;
        }

        let payload = {
            resetPasswordLink,
            newPassword
        }
        try {
            const response = await authApi.resetPassword(payload);
            if (response?.data?.message) {
                setIsReset(true);
            }
            else {
                setState({ ...state, buttonText: 'Reset Password', success:'', error: response.data.error });
            }

            // Redirect to login page with a message
            // navigate("/login");
            
        } catch (error: any) {
            setState({ ...state, buttonText: 'Reset Password', success:'', error: error.response.data.error });
        }
    };

    const resetPasswordForm = () => (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <input
                    value={newPassword}
                    onChange={handleChange('newPassword')}
                    type="password"
                    className="form-control"
                    placeholder="New password"
                    required
                    autoFocus
                    minLength={6}
                />
                
            </div>
            <div className="form-group">
                <input
                        value={confirmNewPassword}
                        onChange={handleChange('confirmNewPassword')}
                        type="password"
                        className="form-control"
                        placeholder="Confirm password"
                        required
                        minLength={6}
                    />
            </div>
            <div className="form-group">
                <button className="btn btn-outline-warning login__form-btn">Reset Password</button>
            </div>
        </form>
    );

    const passwordResetComplete = () => (
        <div style={{color: '#ff835d', fontWeight: 'bold'}}>
            <p>Your password has been reset.<br></br>
                Please sign in with your new password!</p>
        </div>
    )

    return (
        <>
        <div className='Main__login'>
           <div className='login__header'>
               <div className='login__logo-wrap'>
                    <BlackLogoHeading />
               </div>
               <h3>Reset password with Magic</h3>
           </div>
           <div className='login__form'>
               <div className='login__error'>
                   {success && success}
                   {error && error}
               </div>
               {isReset ? passwordResetComplete() : resetPasswordForm()}

               {isReset ? 
                <div className='login__signup'>
                    Finished? <Link to={LOGIN_URL} style={{textDecoration: 'none'}}>Sign In</Link>
                </div> : ''
                }
           </div>

       </div>
       </>
    );
};

export default ResetPassword;

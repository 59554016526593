import axios from "../../lib/axios";
import { toast } from 'react-toastify';

const USER_CONFIG_API = process.env.REACT_APP_USER_CONFIG_API as string;

const getUserConfig = async () => {
    const response = await axios.get(USER_CONFIG_API)
    if (response && response.data) {
        // response.data.error ? toast.error(response.data.error) : toast.success(response.data.success)
    }
    return response;
}

const userApi = {
    getUserConfig: getUserConfig,
}

export default userApi;
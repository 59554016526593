import { createRef, useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import authApi from '../../app/api/authApi';
import { BlackLogoHeading } from '../../features/auth/components/LogoHeading';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
    const [state, setState] = useState({
        email: '',
        error: '',
        success: '',
        buttonText: 'RESET PASSWORD'
    });
    const [emailSent, setEmailSent] =useState(false);
    const [resetLink, setResetLink] = useState('');
    const { email, error, success, buttonText } = state;
    const LOGIN_URL = process.env.REACT_APP_LOGIN_URL as string;
    const form = createRef<any>()
    const handleChange = (email: string) => (e: { target: { value: any; }; }) => {
        setState({...state, [email]: e.target.value, error: '', success: '', buttonText: 'RESET PASSWORD' });
    };

    useEffect(()=> {
        const sendEmail = () => {
            emailjs.sendForm('service_f9bnjaj', 'template_udocf7i', form.current, 'user_qatU9Q3qI9fVpdYGoPuhh').then(res=>{
                console.log(res);
                if (res) {
                    setResetLink('')
                    setEmailSent(true);
                }
            }).catch(err=> 
                console.log(err)
            );
            setResetLink('')
        }

        if (resetLink) {
            sendEmail();
        }
    }, [form, resetLink])

    // Handle Submit
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (emailSent) return;
        try {
            const response = await authApi.forgotPassword({email});
            if (response?.data && response.data.url) {
                setResetLink(response.data.url);
            }else {
                setEmailSent(true);
            }
            
        } catch (error: any) {
            console.log(error);
            setState({ ...state, buttonText: 'RESET PASSWORD',success:'', error: "Password reset failed. Try again later" });
        }
    };



    const loginForm = () => (
        <form ref={form} onSubmit={handleSubmit}>
            <div className="form-group">
                <input
                    name='userEmail'
                    value={email}
                    onChange={handleChange('email')}
                    type="email"
                    className="form-control"
                    placeholder="Type your email"
                    autoFocus
                    required
                />
                <input type="hidden" name='resetLink' value={resetLink}></input>
            </div>
            <div className="form-group">
                <button className="btn btn-outline-warning login__form-btn">{buttonText}</button>
            </div>
        </form>
    );
    
    const emailSentMsg = () => (
         <div style={{color: '#ff835d', fontWeight: 'bold'}}>
            <p>A link to reset password has been sent. <br></br>
                Please check your email!</p>
        </div>
    )

    return (
        <>
         <div className='Main__login'>
            <div className='login__header'>
                <BlackLogoHeading />
                <h3>Reset password with Magic</h3>
            </div>
            <div className='login__form'>
                <div className='login__error'>
                    {success && success}
                    {error && error}
                </div>
                {emailSent ? emailSentMsg() : loginForm()}

                <div className='login__signup'>
                    Finished? <Link to={LOGIN_URL} style={{textDecoration: 'none'}}>Sign In</Link>
                </div>
            </div>

        </div>
        </>
    );
};

export default ForgotPassword;

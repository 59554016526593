import React from "react";
import { useDispatch } from "react-redux";
import Card from "react-bootstrap/Card";
import { useAppSelector } from "../../../app/hooks";
import { showDeleteModal, resetDelete } from "../tradeSlice";
import { getDate, getBroker } from "../util";
import "./tradeCard.scss";
import { Link } from "react-router-dom";

interface Props {
  trade: {
    isOpen: boolean;
    pnl: number;
    symbol: string;
    transaction: string;
    openDate: string | number;
    closedDate: string | number;
    totalShares: number;
    avgBuyPrice: number;
    avgSellPrice: number;
    commission: number;
    fees: number;
    broker: string;
    singleTrades: Array<any>;
    partialClosed: Array<any>;
    sharesLeft: number;
  };
  tradeId: any;
  addToSelectedArr: Function;
  removeFromSelectedArr: Function;
  tradeIdx: any;
}

// Get Avg Price display
const AvgPrice = React.memo(
  ({
    transaction,
    avgBuyPrice,
    avgSellPrice,
  }: {
    transaction: string;
    avgBuyPrice: number;
    avgSellPrice: number;
  }) => {
    if (transaction === "buy") {
      return (
        <>
          <li>
            <span>Entry $: </span>
            <span>
              $
              {avgBuyPrice.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </span>
          </li>
          {avgSellPrice ? (
            <li>
              <span>Exit $: </span>
              <span>
                $
                {avgSellPrice.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
              </span>
            </li>
          ) : (
            ""
          )}
        </>
      );
    } else {
      return (
        <>
          <li>
            <span>Entry $: </span>
            <span>
              $
              {avgSellPrice.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </span>
          </li>
          {avgBuyPrice ? (
            <li>
              <span>Exit $: </span>
              <span>
                $
                {avgBuyPrice.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
              </span>
            </li>
          ) : (
            ""
          )}
        </>
      );
    }
  }
);
// Get date display
const DateDisplay = React.memo(
  ({
    openDate,
    closedDate,
  }: {
    openDate: number | string;
    closedDate: number | string;
  }) => {
    return (
      <>
        <li>
          <span>Entry Date: </span>
          <span>{getDate(openDate)}</span>
        </li>
        {closedDate ? (
          <li>
            <span>Exit Date: </span>
            <span>{getDate(closedDate)}</span>
          </li>
        ) : (
          ""
        )}
      </>
    );
  }
);
// Display P&L
const PnlDisplay = React.memo(
  ({
    pnl,
    isOpen,
    partialClosed,
  }: {
    pnl: number;
    isOpen: boolean;
    partialClosed: Array<any> | "";
  }) => {
    return (
      <>
        {!isOpen || partialClosed ? (
          pnl >= 0 ? (
            <span style={{ fontWeight: "bold" }} className="_profit-color">
              $
              {pnl.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </span>
          ) : (
            <span style={{ fontWeight: "bold" }} className="_loss-color">
              ${pnl.toLocaleString(undefined, { maximumFractionDigits: 2 })}
            </span>
          )
        ) : (
          ""
        )}
        {isOpen && partialClosed ? (
          <span style={{ fontSize: ".6em", color: "#e1b382" }}>
            &nbsp;(Partial)
          </span>
        ) : (
          ""
        )}
      </>
    );
  }
);

// Main Trade Card display
const TradeCard = (props: Props) => {
  const {
    isOpen,
    pnl,
    symbol,
    transaction,
    openDate,
    closedDate,
    totalShares,
    avgBuyPrice,
    avgSellPrice,
    commission,
    fees,
    broker,
    singleTrades,
    partialClosed,
    sharesLeft,
  } = props.trade;
  const { addToSelectedArr, removeFromSelectedArr, tradeId, tradeIdx } = props;
  const dispatch = useDispatch()<any>;
  const { config } = useAppSelector((state: any) => state.user);
  const { defaultAccount } = config;

  const deleteCard = () => {
    dispatch(resetDelete());
    let tradeIds: Array<string> = [];
    singleTrades.forEach((trade) => {
      tradeIds.push(trade.tradeId);
    });

    const payload = {
      accId: defaultAccount,
      tradeIds: tradeIds,
    };
    dispatch(showDeleteModal(payload));
  };
  const handleSelect = (e: any) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      selectCard();
    } else {
      deSelectCard();
    }
  };
  const selectCard = () => {
    let tradeIds: Array<string> = [];
    singleTrades.forEach((trade) => {
      tradeIds.push(trade.tradeId);
    });
    addToSelectedArr(tradeIds);
  };
  const deSelectCard = () => {
    let tradeIds: Array<string> = [];
    singleTrades.forEach((trade) => {
      tradeIds.push(trade.tradeId);
    });
    removeFromSelectedArr(tradeIds);
  };

  return (
    <>
      <Card
        className="tradeCard"
        border={pnl > 0 ? "success" : pnl < 0 ? "danger" : "secondary"}
      >
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            padding: ".5em",
          }}
        >
          <input
            id="tradeId"
            type="checkbox"
            onChange={(e: any) => handleSelect(e)}
          />
        </div>
        <div
          style={{ marginTop: ".5em", fontSize: "1.5em", fontWeight: "bold" }}
        >
          <span style={{ textTransform: "uppercase", color: "#34435e" }}>
            {symbol}
          </span>
          {isOpen ? (
            <span style={{ color: "#e1b382", fontSize: ".7em" }}>
              {!partialClosed.length ? (
                <div>(Open)</div>
              ) : (
                <div>(Part-Open)</div>
              )}
            </span>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            paddingRight: ".5em",
            fontSize: "1.5em",
            cursor: "pointer",
          }}
          onClick={() => deleteCard()}
        >
          X
        </div>
        <Link
          to={`/trades/detail/${tradeIdx}`}
          style={{ textDecoration: "none" }}
        >
          <Card.Body className="tradeCard-body">
            <Card.Title>
              <PnlDisplay
                pnl={pnl}
                isOpen={isOpen}
                partialClosed={partialClosed.length ? partialClosed : ""}
              />
            </Card.Title>

            <ul
              style={{
                listStyleType: "none",
                margin: "0",
                textAlign: "center",
              }}
            >
              {/* Position */}
              <li>
                <span>Position: </span>
                {transaction === "buy" ? "Long" : "Short"}
              </li>
              {/* Entry & Exit Price */}
              <AvgPrice
                transaction={transaction}
                avgBuyPrice={avgBuyPrice}
                avgSellPrice={avgSellPrice}
              />
              {/* Shares */}
              <li>
                <span>Shares: </span>
                {totalShares}
              </li>
              {/* Shares */}
              {partialClosed.length && isOpen ? (
                <li>
                  <span>Shares Open: </span>
                  {sharesLeft}
                </li>
              ) : (
                ""
              )}
              <li>
                <span>Executions: </span>
                {singleTrades.length}
              </li>
              {/* Date */}
              <DateDisplay openDate={openDate} closedDate={closedDate} />
              {/* Commistion */}
              {commission ? (
                <li>
                  <span>Commission: </span>
                  <span>${commission.toFixed(2)}</span>
                </li>
              ) : (
                ""
              )}
              {/* Fees */}
              {fees ? (
                <li>
                  <span>Fees: </span>
                  <span>${fees.toFixed(2)}</span>
                </li>
              ) : (
                ""
              )}
              {/* Broker */}
              <li>
                <span>Broker: </span>
                <span>{getBroker(broker)}</span>
              </li>
            </ul>
          </Card.Body>
        </Link>
      </Card>
    </>
  );
};

export default TradeCard;

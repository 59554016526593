import axios from "../../lib/axios";
import { toast } from 'react-toastify';

const TRADE_API = process.env.REACT_APP_TRADE_API as string;
const IMPORT_TRADE_API = process.env.REACT_APP_IMPORT_TRADE_API as string;
const DELETE_TRADE_API = process.env.REACT_APP_DELETE_TRADE_API as string;

const manualTrade = async (trade: any) => {
    const response = await axios.post(TRADE_API, trade)
    if (response && response.data) {
        response.data.error ? toast.error(response.data.error) : toast.success(response.data.success)
    }
    return response;
}
const importTrade = async (trade: any) => {
    const response = await axios.post(IMPORT_TRADE_API, trade)
    if (response && response.data) {
        response.data.error ? toast.error(response.data.error) : toast.success(response.data.success)
    }
    return response;
}
const fetchTrade = async (pg:any, acc:any) => {
    console.log(acc)
    const params = {
        params: {
            pg: pg,
            accId: acc
        }
    }
    const response = await axios.get(TRADE_API, params)
    if (response && response.data) {
        response.data.error ? toast.error(response.data.error) : toast.success(response.data.success)
    }
    return response;
}
interface DeletePayload {
    accId: string,
    tradeIds: Array<string>
}
const deleteTrade = async (payload: DeletePayload) => {
    if (!payload) return
    const response = await axios.delete(DELETE_TRADE_API, {data: payload})
    if (response && response.data) {
        response.data.error ? toast.error(response.data.error) : toast.success(response.data.success)
    }
    return response;
}

const tradeApi = {
    manualTrade: manualTrade,
    importTrade: importTrade,
    fetchTrade: fetchTrade,
    deleteTrade: deleteTrade
}

export default tradeApi;
import { setLocalStorage } from "../../hooks/useLocalStorage";
import axios from "../../lib/axios";

const REGISTER_API = process.env.REACT_APP_REGISTER_API as string;
const LOGIN_API = process.env.REACT_APP_LOGIN_API as string;
const AUTH_API = process.env.REACT_APP_AUTH_USER_URL as string;
const FORGOT_PASSWORD_API = process.env.REACT_APP_FORGOT_PASSWORD_API as string;
const RESET_PASSWORD_API = process.env.REACT_APP_RESET_PASSWORD_API as string;

const registerUser = async (user: any) => {
    const response = await axios.post(REGISTER_API, user)

    if (response && response.data && response.data.user) {
        // setLocalStorage('user', response.data.user)
        // add cookie
    }

    return response
}

const loginUser = async (user: any) => {
    const response = await axios.post(LOGIN_API, user)

    if (response && response.data && response.data.user) {
        // setLocalStorage('user', response.data.user)
        //add cookie
    }
    return response;
}

const forgotPassword = async (email: any) => {
    const response = await axios.put(FORGOT_PASSWORD_API, email)
    return response;
}
const resetPassword = async (token: any) => {
    const response = await axios.put(RESET_PASSWORD_API, token)
    return response;
}

const checkUserAuth = async () => {
    const response = await axios.get(AUTH_API);
    
    if (response && response.data && response.data.user) {
        // setLocalStorage('user', response.data.user);
        // add cookie
    }
    return response;
}

const authApi = {
    loginUser: loginUser,
    registerUser: registerUser,
    forgotPassword: forgotPassword,
    resetPassword: resetPassword,
    checkUserAuth: checkUserAuth
}

export default authApi;
import { configureStore } from "@reduxjs/toolkit";
import authReducer from '../features/auth/authSlice'
import userReducer from '../features/dashboard/userSlice'
import tradeReducer from '../features/dashboard/tradeSlice'
import thunkMiddleware from 'redux-thunk';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        trade: tradeReducer,
        user: userReducer
    },
    middleware: [thunkMiddleware],
})
export type RootState = ReturnType<typeof store.getState>
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import TradeCard from "../../features/dashboard/components/TradeCard";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "react-bootstrap/Spinner";

const viewStateObj = {
  maxCardPerPage: 16,
  currentSet: 1,
  hasMore: false,
};

const TradeGridView = (props: any) => {
  const { tradeData, addToSelectedArr, removeFromSelectedArr } = props;
  const [viewTradeData, setViewTradeData] = useState(
    tradeData.slice(0, viewStateObj.maxCardPerPage)
  );
  const [viewState, setViewState] = useState(viewStateObj);

  useEffect(() => {
    setViewTradeData(
      tradeData.slice(0, viewState.currentSet * viewState.maxCardPerPage)
    );
    if (tradeData.length > 16) setViewState({ ...viewState, hasMore: true });
  }, [tradeData]);

  const fetchMoreData = () => {
    setViewState({ ...viewState, currentSet: viewState.currentSet + 1 });

    setTimeout(() => {
      let nextDataSet = tradeData.slice(
        0,
        (viewState.currentSet + 1) * viewState.maxCardPerPage
      );
      setViewTradeData(nextDataSet);
      if (nextDataSet.length === tradeData.length)
        setViewState({ ...viewState, hasMore: false });
    }, 100);
  };

  const refreshData = () => { };
  console.log("viewTradeData");

  return (
    <>
      <InfiniteScroll
        style={{ overflow: "inherit" }}
        dataLength={viewTradeData.length}
        next={fetchMoreData}
        hasMore={viewState.hasMore}
        scrollThreshold={1}
        loader={
          <div style={{ marginTop: "5em" }}>
            <Spinner
              as="span"
              animation="border"
              variant="dark"
              role="status"
              aria-hidden="true"
            />
          </div>
        }
      >
        <Row
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2em",
          }}
          xs={1}
          sm="auto"
          className="g-4"
        >
          {viewTradeData.map((trade: any, idx: any) => (
            <Col sm key={trade.singleTrades[0].tradeId}>
              <TradeCard
                trade={trade}
                addToSelectedArr={(tradeIds: Array<string>) =>
                  addToSelectedArr(tradeIds)
                }
                removeFromSelectedArr={(tradeIds: Array<string>) =>
                  removeFromSelectedArr(tradeIds)
                }
                tradeId={trade.singleTrades[0].tradeId}
                tradeIdx={idx}
              />
            </Col>
          ))}
        </Row>
      </InfiniteScroll>
    </>
  );
};

export default TradeGridView;

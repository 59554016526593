import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify'
import { BiImport } from 'react-icons/bi';
import Spinner from 'react-bootstrap/Spinner';
import { useAppSelector } from '../../../app/hooks';
import { importTrade, showImportTradeModal as showImportModal } from '../tradeSlice';
import { csvParser } from '../util/csvParser';
import '././modals/modal.scss';

const ImportTrade = () => {
    const [file, setFile] = useState(null) 
    const [isSent, setIsSent] = useState(false)
    const [brokerSelected, setBrokerSelected] = useState('')

    const { isImportSuccess, showImportTradeModal, isImportLoading } = useAppSelector((state: any) => state.trade)
    const { config } = useAppSelector((state: any) => state.user)
    const dispatch = useDispatch()<any>

    useEffect(()=> {
        if (isImportSuccess) {
            setFile(null);
            (document.getElementById('importFile') as HTMLInputElement).value = '';
            hideImportTradeModal();
        }
    },[isImportSuccess])

    const hideImportTradeModal = () => {
        if (!isImportLoading) dispatch(showImportModal(false));
    }

    // Handle File Change
    const handleChangeFile = (e:any) => {
        const csvFile = e.target.files[0];
        
        if (!csvFile) setFile(null);
        else if (csvFile.type.indexOf('csv') === -1) {
            setFile(null)
            toast.error('Unsupported file type.')
        } else {
            setFile(csvFile)
        }
    }
    // Handle Import Submit
    const handleSubmit = async (e:any) => {
        e.preventDefault();
        if (!file || isSent || !brokerSelected) return;
        
        setIsSent(true);
        const parseResult:any = await csvParser(file, brokerSelected)

        let payload = {
            accId: config?.defaultAccount, 
            data: parseResult.data
        }

        if (parseResult?.success && !isSent) dispatch(importTrade(payload))
        setTimeout(()=> {
            if (parseResult?.success) {
                setIsSent(false)
            }
            else if (parseResult?.error) {
                toast.error(parseResult.error)
                setIsSent(false)
            }
        },1000)
    }
    // Handle Broker Selector
    const handleSelectBroker = (e:any) => {
        let broker = e.target.value;
        console.log(broker)
        setBrokerSelected(broker);
    }

    return (
    <>
        <Modal 
            aria-labelledby="contained-modal-title-vcenter" 
            centered 
            show={showImportTradeModal} 
            onHide={hideImportTradeModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className="modal__title sideMenu__button--import">
                        <BiImport size={30}/><span className='modal__title-text'>Import Trade</span>
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} style={{padding: '1em'}}>
                    <Form.Group style={{marginBottom: '1em'}}>
                        <Form.Label>Broker</Form.Label>
                        <Form.Select style={{fontWeight:'bold'}} value={brokerSelected} onChange={handleSelectBroker} id="brokerSelector">
                            <option value="" disabled>Select Broker</option>
                            <option value="TD Ameritrade">TD Ameritrade</option>
                            <option value="TradeStation">TradeStation</option>
                            <option value="TradeZero">TradeZero</option>
                            {/* <option value="Interactive Broker">Interactive Broker</option> */}
                        </Form.Select>
                    </Form.Group>
           
                    <Form.Group controlId="importFile" className="mb-3">
                        <Form.Label>CSV File Only</Form.Label>
                        <Form.Control type="file" accept=".csv" onChange={handleChangeFile}/>
                    </Form.Group>
                </Form>

                <div style={{paddingLeft:'1em'}}>
                    <div style={{marginBottom: '.5em'}}>
                        <b>To Testers:</b> You can use the following CSV files to test import.
                    </div>
                    <div>
                        <a href="https://drive.google.com/u/0/uc?id=13NVICzN8PpZBgibudTinURJbY2D_TVQL&export=download" rel="noreferrer" download={"TD_Ameritrade_1.csv"} >TD_Ameritrade_1.csv</a>
                    </div>
                    <div>
                        <a href="https://drive.google.com/u/0/uc?id=13TqeWqHWkSMDoqi3R_HMTWUXARADWi_F&export=download" rel="noreferrer" download={"TradeStation_1.csv"} >TradeStation_1.csv</a>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer style={{justifyContent:'center'}}>
                {!isImportLoading 
                    ? <Button onClick={handleSubmit}>Import</Button>
                    : <Button disabled>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            variant="dark"
                            role="status"
                            aria-hidden="true"
                        />
                        <span style={{paddingLeft:'.5em'}}>Importing...</span>
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default ImportTrade
import React from 'react'

export const Page404 = () => {
  return (
    <>
      <div><h1>404</h1></div>
      <div><h1>Wrong Address!!</h1></div>
    </>
  )
}

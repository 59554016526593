import Papa from "papaparse";
import { toServerDate } from "./index";

export const csvParser = async (file: File, broker: string) => {
  if (!broker || !file) return;
  let resultData = [] as any;
  const newProm = new Promise((resolve, reject) => {
    switch (broker) {
      case "TradeStation": {
        // Parse local CSV file
        Papa.parse(file, {
          header: true,
          complete: (results: any) => {
            let parsedTrades: Array<object> = results.data;

            if (!Object.hasOwn(parsedTrades[0], "ADP")) {
              reject("Invalid file content for selected broker.");
            } else {
              let finalFormat = [] as any;
              parsedTrades.forEach((element: any) => {
                if (
                  !element ||
                  !element["Account Number"] ||
                  !element["Symbol"]
                )
                  return;
                console.log(toServerDate(element["Activity Time"]));

                let tradeObj = {
                  symbol: element["Symbol"].trim().toLowerCase(),
                  transaction:
                    element["Transaction"].trim().toLowerCase() === "buy"
                      ? "buy"
                      : "sell",
                  shares: Number(element["Quantity"]),
                  price: Number(element["Price"]),
                  commission: Number(element["Commission"]),
                  fees: 0,
                  broker: "ts",
                  dateTime: toServerDate(element["Activity Time"]),
                };
                finalFormat.push(tradeObj);
              });
              resultData = finalFormat;
              resolve("done");
            }
          },
          error: (err) => {
            reject("Failed reading file.");
          },
        });
        break;
      }
      case "TradeZero": {
        // Parse local CSV file
        Papa.parse(file, {
          header: true,
          complete: (results: any) => {
            let parsedTrades: Array<object> = results.data;

            console.log(parsedTrades);
            let startIdx = 2;

            if (!startIdx) {
              reject("Invalid file content for selected broker.");
            } else {
              let finalFormat = [] as any;
              parsedTrades.forEach((element: any) => {
                if (!element || !element["Account"] || !element["Symbol"])
                  return;
                const totalFees =
                  element["ECN Add"] +
                  element["ECN Remove"] +
                  element["SEC"] +
                  element["TAF"];
                const dateAndTime = toServerDate(
                  element["T/D"] + ", " + element["Exec Time"]
                );

                let tradeObj = {
                  symbol: element["Symbol"].trim().toLowerCase(),
                  transaction: ["b", "bc"].includes(
                    element["Side"].trim().toLowerCase()
                  )
                    ? "buy"
                    : "sell",
                  shares: Number(element["Qty"]),
                  price: Number(element["Price"]),
                  commission: Number(element["Comm"]),
                  fees: totalFees,
                  broker: "tz",
                  dateTime: dateAndTime,
                  clrBroker: element["Clr Broker"],
                };
                finalFormat.push(tradeObj);
              });
              resultData = finalFormat;
              console.log(resultData);
              resolve("done");
            }
          },
          error: (err) => {
            reject("Failed reading file.");
          },
        });
        break;
      }
      case "TD Ameritrade": {
        // Parse local CSV file
        Papa.parse(file, {
          header: false,
          complete: (results: any) => {
            let parsedTrades: Array<Array<string>> = results.data;
            let startIdx = 0;
            let tradeArray = [];

            // Find relative starting point in the file
            for (let i = 0; i < parsedTrades.length; i++) {
              if (parsedTrades[i][0] === "Cash Balance") {
                startIdx = i;
                break;
              }
            }

            if (!startIdx) {
              reject("Invalid file content for selected broker.");
            } else {
              console.log(parsedTrades[startIdx + 11]);
              // Starting idx of trades is +2 from "Cash Balance" index
              for (let i = startIdx + 2; i < parsedTrades.length; i++) {
                // End of trade index is array of ['']
                if (parsedTrades[i].length === 1 && parsedTrades[i][0] === "") {
                  break;
                } else if (parsedTrades[i][2] === "TRD") {
                  const desc = parsedTrades[i][4];
                  const parts = desc.split(" ");

                  if (parts.length !== 4) {
                    throw new Error("String format is incorrect");
                  }

                  const action = parts[0] === "BOT" ? "buy" : "sell";
                  const shares = Math.abs(
                    parseInt(parts[1].replace(/,/g, ""), 10)
                  );
                  const symbol = parts[2];
                  const price = parseFloat(parts[3].slice(1));

                  let tradeObj = {
                    symbol: symbol,
                    transaction: action,
                    shares: shares,
                    price: price,
                    commission: parsedTrades[i][6] || 0,
                    fees: parsedTrades[i][5] || 0,
                    broker: "tda",
                    dateTime: toServerDate(
                      parsedTrades[i][0] + " " + parsedTrades[i][1]
                    ),
                    balance: parsedTrades[i][8],
                  };
                  tradeArray.push(tradeObj);
                }
              }
              resultData = tradeArray;
              resolve("done");
            }
          },
          error: (err) => {
            reject("Failed reading file.");
          },
        });
        break;
      }
      default: {
        reject("Unsupported Broker.");
      }
    }
  });
  try {
    const result = await newProm;
    return { success: result, data: resultData };
  } catch (err) {
    return { error: err };
  }
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import authApi from "../../app/api/authApi";
import { getLocalStorage, setLocalStorage } from "../../hooks/useLocalStorage"

// const user = getLocalStorage('user');

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    msg: '',
    isLoggedIn: false,
}
// Register User
export const registerUser = createAsyncThunk('auth/register', async (user: any, { rejectWithValue }) => {
    try {
        const response = await authApi.registerUser(user);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data.error)
        }
        else if (response.data?.error) {
            return rejectWithValue(response.data.error);
        }
        return response.data
    } catch (err: any) {
        const msg = (err.response && err.response.data && err.response.data.message) || err.message || err.toString();
        return rejectWithValue(msg);
    }
})
// Login User
export const loginUser = createAsyncThunk('auth/login', async (user: any, { rejectWithValue }) => {
    try {
        const response = await authApi.loginUser(user);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data.error)
        }
        else if (response.data?.error) {
            return rejectWithValue(response.data.error);
        }
        if (response.data?.token) {
            setLocalStorage('a_token', response.data.token)
            setLocalStorage('user', response.data.user)
        }
        return response.data
    } catch (err: any) {
        const msg = (err.response && err.response.data && err.response.data.message) || err.message || err.toString();
        return rejectWithValue(msg);
    }
})
// Check User's Auth
export const checkUserAuth = createAsyncThunk('auth/checkUserAuth', async () => {
    try {
        const response = await authApi.checkUserAuth();

        return response.data
    } catch (err: any) {
        const msg = (err.response && err.response.data && err.response.data.message) || err.message || err.toString();
        return msg;
    }
})

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetState: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.msg = ''
        },
    },
    extraReducers: (builder) => {
        builder
            // Register User
            .addCase(registerUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.isSuccess = true
                state.isLoading = false
                state.msg = action.payload.message
            })
            .addCase(registerUser.rejected, (state, action: any) => {
                state.isError = true
                state.isLoading = false
                state.msg = action.payload
            })
            // Login User
            .addCase(loginUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isLoggedIn = true
                state.isSuccess = true
                state.isLoading = false
                state.msg = action.payload.message
            })
            .addCase(loginUser.rejected, (state, action: any) => {
                state.isLoggedIn = false
                state.isError = true
                state.isLoading = false
                state.msg = action.payload
            })
            //Check User Auth
            .addCase(checkUserAuth.fulfilled, (state, action) => {
                state.isLoggedIn = action.payload.isLoggedIn || false
            })
            .addCase(checkUserAuth.rejected, (state, action: any) => {
                state.isLoggedIn = action.payload.isLoggedIn
            })
    }
})

export const { resetState } = authSlice.actions;
export default authSlice.reducer;
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { fetchTrade } from "../../features/dashboard/tradeSlice";
import Spinner from "react-bootstrap/esm/Spinner";
import { Container, Row, Col, Card, Table, ListGroup } from "react-bootstrap";
import { getDateAndTime } from "../../features/dashboard/util";
import dayjs from "dayjs";
import "./tradeDetail.scss";

export default function TradeDetail() {
  const { config } = useAppSelector((state: any) => state.user);
  const { isLoading, isSuccess, tradeData } = useAppSelector(
    (state: any) => state.trade
  );

  const { id } = useParams();
  const dispatch = useDispatch()<any>;
  const { defaultAccount } = config;
  const trades = id ? tradeData[id] : [];

  useEffect(() => {
    if (defaultAccount) dispatch(fetchTrade({ pg: 1, accId: defaultAccount }));
  }, [defaultAccount, dispatch]);

  useEffect(() => {
    if (isLoading) {
      console.log("fetching data");
    }
  }, [isLoading]);

  const calculateHeldDuration = (openDate: number, closedDate: number) => {
    const open = dayjs(String(openDate), "YYYYMMDDHHmmss");
    const close = dayjs(String(closedDate), "YYYYMMDDHHmmss");
    const diff = close.diff(open);
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <>
      {isLoading && !isSuccess && (
        <Spinner
          as="span"
          animation="border"
          variant="dark"
          role="status"
          aria-hidden="true"
        />
      )}
      {isSuccess && (
        <Container className="container-custom">
          <Row
            xs={1}
            sm="auto"
            className="g-4"
            style={{ justifyContent: "center" }}
          >
            <Col>
              <Card className="tradeCard-boxShadow">
                <Card.Header className="card-header-custom trade-details">
                  Trade Details
                </Card.Header>
                <Card.Body>
                  <Card.Title className="card-title-custom trade-details">
                    Trade Summary
                  </Card.Title>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <strong>Symbol:</strong> {trades.symbol.toUpperCase()}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Transaction:</strong>{" "}
                      {trades.transaction.toUpperCase() === "BUY"
                        ? "Long"
                        : "Short"}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Broker:</strong> {trades.broker}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Open Date:</strong>{" "}
                      {getDateAndTime(trades.openDate).toLocaleString()}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Closed Date:</strong>{" "}
                      {getDateAndTime(trades.closedDate).toLocaleString()}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Average Buy Price:</strong> $
                      {trades.avgBuyPrice.toFixed(2)}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Average Sell Price:</strong> $
                      {trades.avgSellPrice.toFixed(2)}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Profit/Loss:</strong>{" "}
                      <span
                        className={
                          trades.pnl >= 0 ? "pnl-positive" : "pnl-negative"
                        }
                      >
                        ${trades.pnl.toFixed(2)}
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Total Shares:</strong> {trades.totalShares}
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="tradeCard-boxShadow statistics">
                <Card.Header className="card-header-custom statistics">
                  Statistics
                </Card.Header>
                <Card.Body>
                  <Card.Title className="card-title-custom statistics">
                    Trade Statistics
                  </Card.Title>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <strong>Total Shares Bought:</strong>{" "}
                      {trades.stats.totalSharesBought}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Total Amount Bought:</strong> $
                      {trades.stats.totalAmountBought.toFixed(2)}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Total Shares Sold:</strong>{" "}
                      {trades.stats.totalSharesSold}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Total Amount Sold:</strong> $
                      {trades.stats.totalAmountSold.toFixed(2)}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Accumulated PnL:</strong> $
                      {trades.stats.accPnl.toFixed(2)}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Held Duration:</strong>{" "}
                      {calculateHeldDuration(
                        trades.openDate,
                        trades.closedDate
                      )}
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Card className="tradeCard-boxShadow executions">
                <Card.Header className="card-header-custom executions">
                  Executions
                </Card.Header>
                <Card.Body style={{ overflow: "auto" }}>
                  <Card.Title className="card-title-custom executions">
                    Trade Executions
                  </Card.Title>
                  <Table striped bordered hover className="table-executions">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Transaction</th>
                        <th>Shares</th>
                        <th>Price</th>
                        <th>Commission</th>
                        <th>Fees</th>
                        <th>Broker</th>
                        <th>Date Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trades.singleTrades.map((trade: any, idx: any) => (
                        <tr key={trade._id}>
                          <td>{idx + 1}</td>
                          <td>{trade.transaction.toUpperCase()}</td>
                          <td>{trade.shares}</td>
                          <td>${trade.price.toFixed(2)}</td>
                          <td>${trade.commission.toFixed(2)}</td>
                          <td>${trade.fees}</td>
                          <td>{trade.broker}</td>
                          <td>{getDateAndTime(trade.dateTime)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import userApi from "../../app/api/userApi";

const initialState = {
    config:  '',
    user: '',
    isLoading: false,
    isSuccess: false,
    isError: false,
    msg: '',
}
// Get User Config
export const getUserConfig = createAsyncThunk('app/user-config', async () => {
    try {
        const response = await userApi.getUserConfig();

        return response.data
    } catch (err: any) {
        const msg = (err.response && err.response.data && err.response.data.message) || err.message || err.toString();
        return msg;
    }
})

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetState: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.msg = ''
        }
    },
    extraReducers: (builder) => {
        builder
            // Register User
            .addCase(getUserConfig.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUserConfig.fulfilled, (state, action) => {
                state.isSuccess = true
                state.isLoading = false
                state.config = action.payload.userConfig
                state.msg = action.payload.message
                state.user = action.payload.user
            })
            .addCase(getUserConfig.rejected, (state, action: any) => {
                state.isError = true
                state.isLoading = false
                state.msg = action.payload
                state.config = initialState.config
            })
    }
})

export const { resetState } = userSlice.actions;
export default userSlice.reducer;
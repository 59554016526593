import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import {
  showDeleteModal,
  fetchTrade,
  resetDelete,
  resetState,
  showDeleteAllModal,
} from "../../features/dashboard/tradeSlice";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import DeleteModal from "../../features/dashboard/components/modals/DeleteModal";
import EnterTrade from "../../features/dashboard/components/EnterTrade";
import ImportTrade from "../../features/dashboard/components/ImportTrade";
import "./dashboard.scss";
import TradeGridView from "./TradeGridView";
import OverviewChart from "../../features/dashboard/components/charts/OverviewChart";

function Dashboard() {
  const { config, user } = useAppSelector((state: any) => state.user);
  const {
    isLoading,
    isSuccess,
    isError,
    tradeData,
    sort,
    isDeleteLoading,
    isDeleteSuccess,
    isDeleteError,
    msg,
    isAddSuccess,
    isImportSuccess,
  } = useAppSelector((state: any) => state.trade);
  const dispatch = useDispatch()<any>;
  const { defaultAccount } = config;

  const [selectedCards, setSelectedCards] = useState([] as Array<string>);
  const [isSelectAllCards, setIsSelectAllCards] = useState(false);

  // Fetch Trade Data
  useEffect(() => {
    if (defaultAccount) dispatch(fetchTrade({ pg: 1, accId: defaultAccount }));
  }, []);

  // Success/loading
  useEffect(() => {
    if (isLoading) {
      console.log("fetching data");
    }
    if (isDeleteSuccess) {
      dispatch(resetDelete());
      reset();
      dispatch(fetchTrade({ pg: 1, accId: defaultAccount }));
      setIsSelectAllCards(false);
    }
    if (isAddSuccess || isImportSuccess) {
      dispatch(resetState());
      reset();
      dispatch(fetchTrade({ pg: 1, accId: defaultAccount }));
    }
  }, [isLoading, isAddSuccess, isImportSuccess, isDeleteSuccess]);

  // Error
  useEffect(() => {
    if (isDeleteError || isError) {
      toast.error(msg);
    }
  }, [isDeleteError, isError, msg]);

  // Add to selected
  const addToSelectedArr = (tradeIds: Array<string>) => {
    setSelectedCards([...selectedCards, ...tradeIds]);
  };
  // Remove from Selected
  const removeFromSelectedArr = (tradeIds: Array<string>) => {
    let newArray = selectedCards.filter((element) => {
      return !tradeIds.includes(element);
    });
    setSelectedCards([...newArray]);
  };
  // Delete all cards
  const deleteSelectedCards = () => {
    if (!selectedCards.length || isLoading) return;

    const payload = {
      accId: defaultAccount,
      tradeIds: selectedCards,
    };
    dispatch(showDeleteModal(payload));
  };
  // Select all cards
  const selectAllCards = () => {
    const tradeCards = document.querySelectorAll("#tradeId") as any;
    tradeCards.forEach((element: HTMLInputElement) => {
      element.checked = true;
    });
    const tradeIds = [] as Array<string>;

    tradeData.forEach((trade: ITradeCard) => {
      trade.singleTrades.forEach((singleTrade: SingleTrade) => {
        tradeIds.push(singleTrade.tradeId);
      });
    });
    setSelectedCards(tradeIds);
    setIsSelectAllCards(true);
  };
  // Deselect all cards
  const deselectAllCards = () => {
    const tradeCards = document.querySelectorAll("#tradeId") as any;
    tradeCards.forEach((element: HTMLInputElement) => {
      element.checked = false;
    });
    setSelectedCards([]);
    setIsSelectAllCards(false);
  };
  // Delete all trades
  const deleteAllTrades = () => {
    if (isLoading || !tradeData?.length) return;

    const payload = {
      accId: defaultAccount,
      deleteAll: true,
    };
    dispatch(showDeleteAllModal(payload));
  };
  const reset = () => {
    setSelectedCards([]);
    setIsSelectAllCards(false);
  };

  /****** HTML ******/
  const Head = React.memo(() => (
    <div className="dashboard__head">
      <h2>
        {/* Welcome to Magic Trade Journal{user.firstName ? ', ' + user.firstName : ''}! <br/> */}
        Dashboard
      </h2>
    </div>
  ));

  return (
    <>
      <div style={{ marginBottom: "8em" }}>
        <Head />
        <div className="dashboard__overview-chart">
          <OverviewChart />
        </div>
        <div>
          {tradeData?.length ? (
            <div
              className="dashboard__trade-btn-action"
              style={{ borderTop: "2px solid #bababa", paddingTop: "2em" }}
            >
              {!isSelectAllCards ? (
                <Button
                  style={{ margin: ".5em" }}
                  size="sm"
                  variant="outline-primary"
                  onClick={selectAllCards}
                >
                  Select All
                </Button>
              ) : (
                ""
              )}
              {selectedCards?.length ? (
                <Button
                  style={{ margin: ".5em" }}
                  size="sm"
                  variant="outline-secondary"
                  onClick={deselectAllCards}
                >
                  Deselect All
                </Button>
              ) : (
                ""
              )}

              {selectedCards?.length ? (
                <Button
                  style={{ margin: ".5em" }}
                  size="sm"
                  variant="outline-warning"
                  onClick={deleteSelectedCards}
                >
                  Delete Selected
                </Button>
              ) : (
                ""
              )}
              <Button
                style={{ margin: ".5em" }}
                size="sm"
                variant="outline-danger"
                onClick={deleteAllTrades}
              >
                Delete All
              </Button>
            </div>
          ) : (
            ""
          )}

          <div style={{ maxWidth: "1200px", margin: "auto", padding: "0 1em" }}>
            {isSuccess ? (
              <TradeGridView
                tradeData={tradeData}
                addToSelectedArr={(tradeIds: Array<string>) =>
                  addToSelectedArr(tradeIds)
                }
                removeFromSelectedArr={(tradeIds: Array<string>) =>
                  removeFromSelectedArr(tradeIds)
                }
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <DeleteModal />
      </div>
    </>
  );
}

export default Dashboard;
